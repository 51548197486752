import styled from 'styled-components';

export enum TypeColor {
  'success' = 'rgb(208, 244, 215)',
  'info' = 'rgb(196, 226, 246)',
  'error' = 'rgb(248, 216, 216)',
  'warn' = 'rgb(251, 235, 204)',
}

export type NotificationType = 'success' | 'info' | 'error' | 'warn';

export type Props = {
  type: NotificationType;
};

export const Style = {
  NotificationWrapper: styled.div<Props>`
    background: ${(p) => TypeColor[p.type]};
    padding: 0.5rem;
  `,
};
