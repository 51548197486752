import React, { FC, ReactNode } from 'react';

import { NotificationType, Style } from './style';

export type NotificationProps = {
  type?: NotificationType;
  children: ReactNode;
};

const Notification: FC<NotificationProps> = ({ type = 'info', children }) => (
  <Style.NotificationWrapper type={type}>{children}</Style.NotificationWrapper>
);

export default React.memo(Notification);
