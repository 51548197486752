import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { useCartItems } from '@api/cart-items/useCartItems';
import { usePurchaseAmount } from '@apis/purchase/usePurchaseAmount';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Center } from '@components/layout/center';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Anchor } from '@components/typographies/anchor';
import { Paragraph } from '@components/typographies/paragraph';

interface SideMenuProps {
  btnLabel: string;
  backBtnLabel?: string;
  backBtnOnClick?: () => void;
  disabled?: boolean;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SideMenu = (props: SideMenuProps) => {
  const { orderShop } = useCartItems();
  if (!orderShop) {
    throw new Error('ショップが選択されていません');
  }
  const { amount } = usePurchaseAmount();

  return (
    <>
      <Card
        bg="white"
        width="100%"
        border="1px solid #f39800"
        px="20px"
        py="20px"
        my="100px"
        md={{ width: '300px', marginTop: '0px' }}
      >
        <Block borderBottom="1px solid #d9d9d9" margin="20px 0">
          <Paragraph fontSize="16px" fontWeight="700">
            注文内容
          </Paragraph>
          {orderShop.itemsList.map((item) => (
            <Flex key={item.name} margin="20px 0">
              <Img src={item.imageUrl} />
              <Flex width="70%" height="60px" direction="column">
                <Paragraph fontSize="12px" fontWeight="400">
                  {item.name}
                </Paragraph>
                <Flex width="100%" jc="flex-end">
                  <Flex width="90px">
                    <Paragraph fontSize="12px" fontWeight="400">
                      {item.quantity}点
                    </Paragraph>
                    <Paragraph fontSize="14px" fontWeight="700">
                      ¥{(item.price * item.quantity)?.toLocaleString()}
                    </Paragraph>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Block>
        <Block borderBottom="1px solid #d9d9d9" padding="20px 0">
          <Paragraph fontSize="16px" fontWeight="700">
            注文内容
          </Paragraph>
          <Flex>
            <Paragraph fontSize="14px" lh="20px">
              商品合計金額
            </Paragraph>
            <Paragraph fontSize="14px" lh="20px">
              {amount.orderAmount?.itemAmount.toLocaleString()}円
            </Paragraph>
          </Flex>
          <Flex>
            <Paragraph fontSize="14px" lh="20px">
              送料
            </Paragraph>
            <Paragraph fontSize="14px" lh="20px">
              {amount.orderAmount?.shippingFee.toLocaleString()}円
            </Paragraph>
          </Flex>
        </Block>
        <Flex margin="20px 0">
          <Paragraph fontSize="16px" fontWeight="700">
            合計
          </Paragraph>
          <Paragraph fontSize="20px" fontWeight="700">
            {amount.amount.toLocaleString()}円
          </Paragraph>
        </Flex>
        <Center>
          <VStack spacing="10px">
            <Paragraph fontSize="14px">
              <Anchor
                href={process.env.CUSTOMER_RULE_URL}
                target="_blank"
                rel="noreferrer noopener"
                fontSize="14px"
                color="black"
                bold
              >
                利用規約
              </Anchor>
              及び
              <Anchor
                href={process.env.PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer noopener"
                fontSize="14px"
                color="black"
                bold
              >
                プライバシーポリシー
              </Anchor>
              にご同意します。
            </Paragraph>
            <Button
              width="250px"
              height="50px"
              radius="10px"
              color="white"
              onClick={(e) => props.onSubmit(e)}
              disabled={props.disabled}
            >
              {props.btnLabel}
            </Button>
            <Button
              width="150px"
              height="35px"
              radius="10px"
              bg="gray2"
              border="1px solid #d9d9d9"
              onClick={props.backBtnOnClick || (() => navigate('/cart'))}
            >
              {props.backBtnLabel || 'カートに戻る'}
            </Button>
          </VStack>
        </Center>
      </Card>
    </>
  );
};

const Img = styled.img`
  width: 60px;
  height: 60px;
`;

export default SideMenu;
