import { navigate } from 'gatsby';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CartIcon } from '../../../assets/image/svg/cart.svg';
import SideMenu from '../SideMenu';

import { usePurchase } from '@apis/purchase/usePurchase';
import { usePurchaseAmount } from '@apis/purchase/usePurchaseAmount';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import Notification from '@components/top/Notification';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { ShippingExpectTime } from '@pb/api/v1/common/common_pb';

const ExpectTimeMap = {
  [ShippingExpectTime.Time.NOTIMEZONESPECIFIED]: '時間帯指定なし',
  [ShippingExpectTime.Time.EIGHTTOTWELVE]: '8時から12時まで',
  [ShippingExpectTime.Time.TWELVETOFOURTEEN]: '12時から14時まで',
  [ShippingExpectTime.Time.FOURTEENTOSIXTEEN]: '14時から16時まで',
  [ShippingExpectTime.Time.SIXTEENTOEIGHTEEN]: '16時から18時まで',
  [ShippingExpectTime.Time.EIGHTEENTOTWENTY]: '18時から20時まで',
} as const;

type ExpectTimeValue = keyof typeof ExpectTimeMap;

export const Confirm = () => {
  const { amount } = usePurchaseAmount();
  const { purchaseDetail, purchaseFlowState, onSubmit, errors } = usePurchase();
  const shippingDate = DateTime.fromISO(purchaseFlowState.shippingDate || '');

  return (
    <>
      <Block margin="0 15px" md={{ width: '1000px', margin: '0 auto' }}>
        {errors.map((e, i) => (
          <Notification type="error" key={i}>
            {e.message}
          </Notification>
        ))}
        <Block borderBottom="1px solid #ebebeb" margin="40px 0">
          <Flex>
            <CartIcon stroke="var(--color-orange)" width="20px" height="20px" />
            <Heading as="h2" color="orange">
              ご注文内容の確認
            </Heading>
          </Flex>
        </Block>
        <Flex direction="column" alignItems="flex-start" md={{ flexDirection: 'row' }}>
          <VStack spacing="60px" align="flex-start" py="20px" width="100%" mr="12px">
            <Block borderRadius="10px" border="1px solid #ffebc4" margin="0 0 60px 0" width="100%">
              <Block height="50px" bg="skin" padding="0 20px">
                <Paragraph fontSize="15px" fontWeight="700" lh="50px">
                  発送元:{purchaseDetail?.order?.shop?.name}
                </Paragraph>
              </Block>
              <Block padding="20px">
                <Block borderBottom="1px solid #d9d9d9" paddingBottom="16px">
                  {purchaseDetail?.order?.itemList.map((item) => (
                    <Flex margin="20px" alignItems="flex-start" jc="flex-start" key={item.name}>
                      <Img src={item.url} alt="" />
                      <VStack spacing="32px">
                        <Block padding="10px">
                          <Paragraph lh="30px" fontSize="20px" fontWeight="700">
                            ¥{amount.orderAmount?.itemAmount.toLocaleString()}
                          </Paragraph>
                          <Paragraph lh="20px" fontSize="16px">
                            {item.name}
                          </Paragraph>
                          <Paragraph lh="20px" fontSize="16px" color="gray">
                            {purchaseDetail.order?.shop?.name}
                          </Paragraph>
                        </Block>
                      </VStack>
                    </Flex>
                  ))}
                </Block>
                <Block padding="20px 0">
                  <Flex>
                    <Flex direction="column" alignItems="flex-start" md={{ flexDirection: 'row' }}>
                      <Paragraph fontSize="15px" fontWeight="700" md={{ width: '240px' }}>
                        お届け希望日時
                      </Paragraph>
                      {purchaseFlowState.shippingDate && (
                        <Paragraph fontSize="14px">
                          {shippingDate.toFormat('yyyy/MM/dd')}
                          &#40;{shippingDate.weekdayShort || ''}&#41; &nbsp;
                          {ExpectTimeMap[purchaseFlowState.expectTimeCode as ExpectTimeValue]}
                        </Paragraph>
                      )}
                    </Flex>
                  </Flex>
                </Block>
              </Block>
            </Block>
            <Block
              borderRadius="10px"
              border="1px solid #ffebc4"
              margin="20px 0 60px 0"
              width="100%"
            >
              <Block height="50px" bg="skin" padding="0 20px">
                <Paragraph fontSize="15px" fontWeight="700" lh="50px">
                  購入情報
                </Paragraph>
              </Block>
              {purchaseDetail && (
                <Block>
                  <Flex
                    direction="column"
                    jc="flex-start"
                    alignItems="flex-start"
                    md={{ flexDirection: 'row' }}
                  >
                    <Block padding="16px">
                      <Paragraph my="8px" fontSize="15px" fontWeight="700">
                        購入者情報
                      </Paragraph>
                      <Paragraph fontSize="14px">
                        {`${purchaseDetail.user!.lastName} ${purchaseDetail.user!.firstName}`} 様
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        〒 {purchaseDetail.user!.zipCode}
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.user!.prefecture}
                        {purchaseDetail.user!.municipality}
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.user!.address1 + purchaseDetail.user!.address2}
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.user!.phoneNumber}
                      </Paragraph>
                    </Block>
                    <Block padding="16px">
                      <Paragraph my="8px" fontSize="15px" fontWeight="700">
                        お届け先住所
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.shippingAddress!.title}様
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        〒 {purchaseDetail.shippingAddress!.zipCode}
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.shippingAddress!.municipality}
                        {purchaseDetail.shippingAddress!.address1}
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.shippingAddress!.address2}
                      </Paragraph>
                      <Paragraph my="8px" fontSize="14px">
                        {purchaseDetail.shippingAddress!.phoneNumber}
                      </Paragraph>
                    </Block>
                  </Flex>
                  <Block padding="16px">
                    <Paragraph fontSize="15px" fontWeight="700">
                      メールアドレス
                    </Paragraph>
                    <Paragraph fontSize="14px" lh="40px">
                      {purchaseDetail.user?.email}
                    </Paragraph>
                    <Paragraph fontSize="12px">
                      ※購入完了や配送完了の大切なお知らせを送ります。
                    </Paragraph>
                    <Paragraph fontSize="12px">
                      携帯のメールアドレスをご登録の場合は、「@tasty-mall.com」からのメール
                    </Paragraph>
                    <Paragraph fontSize="12px">
                      を受信てきるよう受信拒否設定等をご確認ください。
                    </Paragraph>
                  </Block>
                  <Block padding="16px">
                    <Paragraph my="8px" fontSize="15px" fontWeight="700">
                      お支払い方法
                    </Paragraph>
                    <Paragraph my="8px" fontSize="14px">
                      クレジットカード一括払い
                    </Paragraph>
                  </Block>
                </Block>
              )}
            </Block>
          </VStack>
          <SideMenu
            btnLabel="注文を確定する"
            backBtnLabel="前の画面に戻る"
            backBtnOnClick={() => navigate(-1)}
            onSubmit={(e) => onSubmit(e)}
          />
        </Flex>
      </Block>
    </>
  );
};

const Img = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 10px;
`;
