import { navigate } from 'gatsby';
import { ComponentProps, useState } from 'react';

import { useCartItems } from '../../api/cart-items/useCartItems';
import { usePurchase as useBasePurchase } from '../../hooks/usePurchase';
import { useSuspenseFetch } from '../../hooks/useSuspenseFetch';
import { useSuspensePromise } from '../../hooks/useSuspensePromise';
import { ErrorResponse } from '../../pb/api/error_pb';
import { AuthorizeRequest, DetailResponse } from '../../pb/api/v1/user/purchase/purchase_pb';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';

import purchaseService from './purchaseService';

import { initAddressForm } from '@redux/slices/purchaseFlow';

type ApiError = {
  field: string;
  code: string;
  message: string;
};

export const usePurchase = () => {
  const { orderShop, removeOrderShop } = useCartItems();
  const [errors, setErrors] = useState<ApiError[]>([]);
  const [_, { authorize }] = useBasePurchase();
  const cardState = useAppSelector((s) => s.cardSlice);
  const purchaseFlowState = useAppSelector((s) => s.purchaseFlowState);
  const dispatch = useAppDispatch();

  if (!orderShop) {
    throw new Error('不正な操作です。もう一度やり直してください');
  }

  const { data: purchaseDetail } = useSuspenseFetch<DetailResponse.AsObject, ErrorResponse>(
    'purchaseDetail',
    () =>
      purchaseService.getPurchaseDetail({
        itemList: orderShop.itemsList,
        maskedCardNumber: cardState.maskedCardNumber,
        shippingAddressid: purchaseFlowState.shippingAddressId!,
        shopid: orderShop.shopId,
      }),
    {
      suspense: true,
      useErrorBoundary: true,
    }
  );

  const onSubmit: ComponentProps<'button'>['onClick'] = useSuspensePromise(
    async (e) => {
      e.preventDefault();

      setErrors([]);

      await authorize({
        token: cardState.cardToken,
        shopid: orderShop.shopId,
        shippingAddressId: purchaseFlowState.shippingAddressId!,
        maskedCardNumber: cardState.maskedCardNumber,
        shippingDate: purchaseFlowState.shippingDate!,
        expectDate: purchaseFlowState.shippingDate!,
        expectTime: purchaseFlowState.expectTimeCode as AuthorizeRequest.AsObject['expectTime'],
        itemsList: orderShop.itemsList.map((c) => {
          return {
            id: c.id,
            qty: c.quantity,
          };
        }),
      });

      // フォーム初期化
      dispatch(initAddressForm());
      // 購入した商品をカートから削除
      removeOrderShop();
      navigate('/order/complete');
    },
    (e) => {
      setErrors([
        {
          field: 'api',
          code: 'authorize_error',
          message: e.getError(),
        },
      ]);
    }
  );

  return { purchaseDetail, purchaseFlowState, onSubmit, errors };
};
