import purchaseService from './purchaseService';

import { useCartItems } from '@api/cart-items/useCartItems';
import { useSuspenseFetch } from '@hooks/useSuspenseFetch';
import useUser from '@hooks/useUser';
import { ErrorResponse } from '@pb/api/error_pb';
import { AmountResponse } from '@pb/api/v1/user/purchase/purchase_pb';

export const usePurchaseAmount = () => {
  const { listShippingAddresses } = useUser();
  const { orderShop } = useCartItems();
  const {
    data: { shippingAddressList },
  } = listShippingAddresses();

  if (!orderShop) {
    throw new Error('不正な操作です。もう一度やり直してください');
  }

  const { data: amount } = useSuspenseFetch<AmountResponse.AsObject, ErrorResponse>(
    'purchaseAmount',
    () =>
      purchaseService.getAmount({
        amountItemList: orderShop.itemsList.map((item) => ({
          itemid: item.id,
          quantity: item.quantity,
        })),
        shopid: orderShop.shopId,
        shippingaddressid: shippingAddressList[0]?.id,
      }),
    {
      suspense: true,
      useErrorBoundary: true,
    }
  );

  return { amount };
};
