import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Loading } from '@components/loading';
import { Confirm } from '@features/order/Confirm';
import { useIsMounted } from '@hooks/useIsMounted';

const ConfirmPage = () => {
  const { ref, isMounted } = useIsMounted();
  return (
    <Layout>
      <Helmet>
        <title>ご注文内容の確認 | {title}</title>
        {Description}
        {NoIndex}
      </Helmet>
      <Suspense fallback={<Loading />}>{isMounted ? <Confirm /> : <div ref={ref} />}</Suspense>
    </Layout>
  );
};

export default ConfirmPage;
